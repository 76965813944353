.testimonail-card {
  border-radius: 50px 20px 50px 0px;
  padding: 24px;
  width: 98%;
}
.descritipn {
  color: #000;
  font-family: "Ubuntu";
  font-weight: 700;
}
