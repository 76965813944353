@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Open+Sans&family=Oswald&family=Ubuntu:wght@300&display=swap");

:root {
  --primary-app-color: #9ecaf3;
  --secondary-app-color: #3971a5;
  --standout-color: #fffb00;
}
.App {
}

* {
  padding: 0px;
  margin: 0px;
}
.herosection {
  background: linear-gradient(
    -90deg,
    var(--primary-app-color),
    var(--secondary-app-color)
  );
  clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 95%, 0 80%, 0% 50%);
}
.nav-items {
  cursor: pointer;
  color: white;
  font-size: 1.3rem;
  font-family: Ubuntu;
}
.brand-name {
  font-family: Kaushan Script;
  color: white;
  font-size: 1.3rem;
}
.px-10 {
  padding-inline: 90px;
}

.herosection-2 {
  padding-top: 60px;
}
.Im-text {
  padding-top: 52px;
  text-align: center;
}
.Im-text {
  font-family: ubuntu;
  color: white;
  font-weight: 700;
  font-size: 1.4rem;
}
.name-Im-text {
  color: var(--standout-color);
  font-size: 28px;
}

.Im-text-enthusiastic {
  font-family: sans-serif;
  color: white;
  font-size: 2.5rem;
}

.Im-text-subheading {
  font-family: "open sans";
  color: white;
  font-size: 1.4rem;
}

.btn-hire-me {
  font-size: 1.3rem;
  font-family: "Ubuntu";
  color: #fff;
  background: transparent;
  border-radius: 25px;
  margin-inline: 12px;
  padding: 5px 40px;
  border: 2px solid white;
  transition: all 0.3s ease-in-out;
  animation: Anim 1s ease-in-out infinite;
}
.btn-hire-me:hover {
  transform: translateY(-5px);
  background-color: var(--standout-color);
  color: #1c1c1c;
}

.btn-resume-me {
  font-size: 1.3rem;
  font-family: "Ubuntu";
  color: #fff;
  background: red;
  border-radius: 25px;
  margin-inline: 12px;
  padding: 5px 40px;
  border: 2px solid white;
  transition: all 0.3s ease-in-out;
}
.btn-resume-me:hover {
  transform: translateY(-5px);
}
@keyframes Anim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.custom-img {
  width: 300px;
  object-fit: contain;
  border-radius: 50%;
  justify-content: center;
  padding-bottom: 12px;
  display: flex;
  border: 1px solid white;
}
/* about me section */
.about-me-text {
  font-family: "ubuntu";
  font-weight: 800;
  font-size: 3.5rem;
  text-decoration: underline;
  letter-spacing: 2px;
  text-align: center;
}
.why-text-sub {
  font-family: "ubantu";
  font-weight: 700;
  font-size: 1.3rem;
  opacity: 0.8;
  text-transform: capitalize;
  text-align: center;
}
.aboutme-inside {
  width: 70%;
  margin-inline: auto;
}
.some-text-about-me {
  font-family: "Ubuntu";
  font-weight: 600;
  font-size: 1.2rem;
  opacity: 0.8;
  text-align: justify;
}
.few-highlights {
  font-family: "Ubuntu";
  padding-block: 12px;
}
.few-text {
  font-family: "Ubuntu";
  font-weight: 900;
  font-size: 1.5rem;
  /* opacity: 0.8; */
  /* margin-top: 40px */
  /* text-align: justify; */
}
.few-list {
  font-family: "Ubuntu";
  font-size: 1.2rem;
}

.resume-options-items {
  width: 1%;
  transition: all 0.3s ease-in-out;
  padding-block: 24px;
  padding-inline: 24px;
  color: #000;
  cursor: pointer;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.resume-options-items:hover {
  width: 100%;
  background: linear-gradient(
    -90deg,
    var(--primary-app-color),
    var(--secondary-app-color)
  );
  color: #fff;
}
.icons-span {
  margin-block: 24px;
  margin-inline: 24px;
}
.selected {
  background: linear-gradient(
    -45deg,
    var(--primary-app-color),
    var(--secondary-app-color)
  );
  width: 100%;
  color: #fff;
}
.bg-new {
  background: linear-gradient(
    -45deg,
    var(--primary-app-color),
    var(--secondary-app-color)
  );
}
.resume-outer-section {
  padding-top: 100px;
  padding-bottom: 100px;
  background: var(--primary-app-color);
  color: #fff;
  clip-path: polygon(0 11%, 100% 0, 100% 100%, 0 100%);
}
.resume-new-section {
  margin-top: 42px;
  background: #fff;
  box-shadow: 10px 10px 31px 2px #1c1c1c;
}

/* contact me part */
.contact-me-part {
  color: #fff;
}

.relative-bg {
  clip-path: polygon(0 5%, 100% 14%, 100% 80%, 0 96%);

  background: linear-gradient(
    -90deg,
    var(--primary-app-color),
    var(--secondary-app-color)
  );
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -4;
}
.contact-inner-part {
  padding-block: 118px;
}
.px-9 {
  padding-inline: 90px;
}
.flex-rows {
  flex-direction: row;
  justify-content: space-between;
}
.navbar-option-mobile {
  display: none;
}
/* the mobileview styling */

@media all and (max-width: 786px) {
  .navbar-option-mobile {
    display: block;
    width: 100vw;
    height: 45px;
    position: fixed;
    z-index: 44;
  }
  .flex-rows {
    flex-direction: column;
    justify-content: start;
  }
  .navbar-new {
    position: fixed;
    height: 100vh;
    width: 60vw;
    z-index: 33;
    transform: translateX(-700px);
  }
  .transition-all {
    transition: all 0.3s ease-in-out;
  }
  .navbar-fixed-new {
    position: fixed;
    height: 100vh;
    width: 60vw;
    z-index: 33;
    /* transform: translateX(-700px); */
  }
  .px-9 {
    padding-inline: 0px;
  }

  .px-10 {
    padding-inline: 10px;
  }
  .nav-items {
    padding-block: 20px;
  }
  .brand-name {
    font-size: 1.4rem;
    padding-inline: 22px;
    margin-top: 6px;
  }
  .bars {
    height: 4px;
    width: 40px;
    background: #fff;
    float: right;
    z-index: 33;
    transform: translateY(22px);
    transition: all 0.3s ease-in-out;
  }
  .bars::before {
    display: block;
    position: relative;
    top: 9px;
    content: "";
    color: #fff;
    width: 40px;
    height: 4px;
    background: #fff;
    transition: all 0.3s ease-in-out;
  }
  .bars::after {
    display: block;
    position: relative;
    bottom: 12px;
    content: "";
    color: #fff;
    width: 40px;
    height: 4px;
    background: #fff;
    transition: all 0.3s ease-in-out;
  }
  .bars.open::before {
    transition: all 0.3s ease-in-out;
    transform: translateY(-10px) rotate(-45deg);
  }
  .bars.open::after {
    transition: all 0.3s ease-in-out;
    transform: translateY(6px) rotate(45deg);
  }

  .Im-text {
    font-size: 1.2rem;
  }
  .name-Im-text {
    font-size: 23px;
  }
  .Im-text-enthusiastic {
    font-size: 1.7rem;
  }
  .Im-text-subheading {
    font-size: 1.2rem;
  }
  .btn-hire-me {
    font-size: 1rem;
    padding: 5px 8px;
    margin-block: 24px;
  }
  .btn-resume-me {
    font-size: 1rem;
    padding: 5px 8px;
    margin-block: 24px;
  }
  .custom-img {
    border: none;
  }
  .about-me-text {
    font-size: 2.7rem;
  }
  .why-text-sub {
    font-size: 1rem;
  }
  .aboutme-inside {
    width: 91%;
  }
  .lottie-1 {
    width: 500px;
  }
  .university-name {
    font-size: 1rem;
  }
  .university-degree {
    font-size: 0.9rem;
  }
  .resume-new-section {
    width: 100% !important;
  }

  /* your content here */
}
