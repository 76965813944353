.contact-me-card {
  background: #1c1c1c;
  width: 80%;
  box-shadow: 10px 10px 63px 4px rgb(243 240 240);
  /* height: 500px; */
  margin-inline: 165px;
}
.get-in-touch {
  color: #fff;
  font-family: "Ubuntu";
  font-weight: 700;
  font-size: 2rem;
}
.input-groups {
  border: none;
  border-radius: 10px;
  padding: 10px 10px;
}
.card-contact-rihgt {
  background: rgb(0, 7, 61);
  box-shadow: 10px 3px 33px 4px rgb(243 240 240);
  border-radius: 20px;
  width: 60%;
  padding: 24px;
}
